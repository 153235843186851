@import "definitions";

//Activity Recording

.activity_recording, .dunnings {
    .list_entry {
        padding: 1em;
        margin-bottom: 1em;
        border: $border;
        background-color: $main_bg;
        box-shadow: $main_box_shadow;

        &.ignore {
            color: #aaa;
        }

        .heading {
            font-size: 1.6em;
        }

        .account_total {
            color: green;
        }

        td {
            border-top: $border;

            &.currency {
                text-align: right;                
            }

            &.sum {
                border-top: 1px solid #999;
                font-weight: bold;
                font-size: 1.2em;
            }

            &.empty {
                border-top: 1px solid transparent;                
            }
        }

        td, th {
            padding: .5em 1em;
        }

        tr.ignore {
            color: #aaa;
        }

        td > span.sent {
            color: green;
        }

        td > span.cancelled {
            color: red;
        }

        &.disabled{
            opacity: 0.5;
        }
    }

    .filter {
        margin-bottom: $mar;
        label {
            padding: $pad_small $pad;
            border: 1px solid #ccc;
            display: inline-block;
            cursor: pointer;

            &.active {
                color: #fff;
                //background-color: #248624;
                background-color: $highlight_color; 
                
                .check{
                    @include sl-icon($fa-var-check-square-o);
                }
            }

            &:not(.active) .checkbox{
                @include sl-icon($fa-var-square-o);
            }

            input[type="checkbox"] {
                display: none;
            }
        }

    }

    .charge_button, .send_button_global {
        position: fixed;
        right: 2em;
        bottom: 2em;
        
        button {
            padding: 1em;
        }
    }

    .sort {
        margin-bottom: $mar;
    }

    form.comment_dialog_form textarea {
        width:100%;
        min-height: 8em;
        margin-bottom: $mar;
    }
}